import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box, Button, Heading, ResponsiveContext } from "grommet"
import theme from "../themes/po3Theme.json"

import Seo from "../components/seo"
import styled from "styled-components"
import Layout from "../components/layout"

const JournalLink = styled(Link)`
  color: ${theme.global.colors.text.light};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`
export default function Journal({ data }) {
  const [filter, setFilter] = React.useState("all")
  const categories = ["all", "journal", "book"]
  let articles = data.allMdx.nodes
  console.log(data.allMdx.nodes[0].slug)
  return (
    <Layout>
      <Seo title="Journal" />
      <Box align="center">
        <Box pad={{ horizontal: "medium" }} margin={{ bottom: "xlarge" }}>
          <Box
            margin={{ vertical: "medium" }}
            direction="row"
            justify="center"
            gap="medium"
          >
            {categories.map((category, i) => (
              <Button
                onClick={e => setFilter(e.target.value)}
                round="small"
                color={filter === category ? "brand" : "light-3"}
                size="medium"
                a11yTitle={`Filter by ${category}`}
                label={category}
                value={category}
                key={i}
              />
            ))}
          </Box>
          <ResponsiveContext.Consumer>
            {size => (
              <Box
                width="xlarge"
                direction="row-responsive"
                gap="small"
                wrap
              >
                {(filter === "all"
                  ? articles
                  : articles.filter(
                      article => article.frontmatter.type === filter
                    )
                ).map((article, i) => {
                  let image = article.frontmatter.image
                    ? getImage(
                        article.frontmatter.image.childImageSharp
                          .gatsbyImageData
                      )
                    : getImage(
                        data.allFile.edges[0].node.childImageSharp
                          .gatsbyImageData
                      )
                  return (
                    <Box flex="grow" basis="1/4" key={i}>
                      <JournalLink to={`/${article.slug}`} key={i}>
                        <Box margin={{ top: "medium", bottom: "medium" }}>
                          <Box height="small">
                            <GatsbyImage
                              alt={article.frontmatter.title}
                              image={image}
                              objectFit="cover"
                              style={{ height: "300px" }}
                            />
                          </Box>
                          <Heading
                            level={3}
                            size="xsmall"
                            margin={{ bottom: "none" }}
                          >
                            {article.frontmatter.title}
                          </Heading>
                        </Box>
                      </JournalLink>
                    </Box>
                  )
                })}
              </Box>
            )}
          </ResponsiveContext.Consumer>
        </Box>
      </Box>
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    allMdx(
      filter: { frontmatter: { type: { in: ["book", "journal"] } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        slug
        frontmatter {
          title
          type
          author
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    allFile(
      filter: { relativePath: { eq: "static/images/northern-lights.jpg" } }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
